import * as React from 'react';
import cx from 'classnames';
import { map, toLower, first, capitalize } from 'lodash';

import {
  useHistory,
} from 'react-router-dom';

import {
  Avatar, Tag, Table, Select,
  Button, Modal, Typography, Tooltip,
} from 'antd';
const { Option } = Select;
const { Column } = Table;
const { Text } = Typography;

import {
  IUser,
  IClient,
  useGetUsers,
  useUpdateClientUsers,
  useGetManagerRoles,
  useUpdateRoleForClient,
} from '@frontend/app/hooks';
import { InfoCircleOutlined } from '@ant-design/icons';

interface IProps {
  client: IClient;

  className?: string;
}

const { useState, useCallback } = React;
import styles from './ClientUsers.scss';
import getColumnSearchProps from '@frontend/app/utils/getColumnSearchProps/getColumnSearchProps';

/**
 * @type {React.FunctionComponent}
 */
const ClientUsers: React.FunctionComponent<IProps> = React.memo((props) => {
  const { client } = props;
  const history = useHistory();
  const { users } = useGetUsers();
  const { updateRoleForClient, loading: updatingRole } = useUpdateRoleForClient();
  const { updateClientUsers, loading: updating } = useUpdateClientUsers();
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [userIds, setUserIds] = useState(map(client.users, 'id'));

  const { loading, roles } = useGetManagerRoles();

  const handleRoleChange = useCallback((managerId: string, role: string) => {
    updateRoleForClient({
      variables: {
        id: managerId,
        clientId: client.id,
        role,
      },
    });
  }, [client, updateRoleForClient]);

  return (
    <div className={cx(styles.ClientUsers, props.className)}>
      <div className={styles.header}>
        <Text ellipsis>Users who have access to this client.</Text>
        <Button
          className={styles.manageUsersButton}
          type="primary"
          onClick={() => {
            setUserIds(map(client.users, 'id'));
            setShowAccessModal(true);
          }}
        >
          Manage Client Users
        </Button>
      </div>
      <Table
        rowKey={(user: IUser) => user.id}
        dataSource={client.users}
        className={styles.table}
        loading={loading || updatingRole}
      >
        <Column title="Name" key="name" render={(_, user: IUser) => (
          <span
            className={styles.nameCell}
            onClick={() => history.push(`/users/${user.id}`)}
          >
            {!user.auth0User && (
              <Tooltip
                title="This user has been deleted from Auth0."
              >
                <InfoCircleOutlined className={styles.alertIcon} />
              </Tooltip>
            )}
            <Avatar className={styles.avatar} src={user.auth0User?.picture} />
            <Text ellipsis className={styles.name}>{user.name}</Text>
          </span>
        )} {...getColumnSearchProps('name')} />
        <Column title="Email" dataIndex="email" />
        <Column title="Email Verified" key="emailVerified" render={(_, user: IUser) => (
          <span>
            <Tag color={user.auth0User?.emailVerified ? 'green' : 'yellow'}>
              {user.auth0User?.emailVerified + ''}
            </Tag>
          </span>
        )} />
        <Column
          title="Role"
          key="role"
          render={(_, manager: IUser) => {
            const clientsData = manager.auth0User?.appMetadata?.clients || {};
            const currentRole = first(clientsData[client.id]?.roles) as string;

            return (
              <Select
                value={currentRole}
                onChange={(role) => handleRoleChange(manager.id, role)}
              >
                {map(roles, (role) => (
                  <Option key={role.id} value={role.name}>
                    {capitalize(role.description)}
                  </Option>
                ))}
              </Select>
            );
          }}
        />
        <Column title="Admin Portal Access" key="admin" render={(_, user: IUser) => (
          <span>
            <Tag color={user.isAdmin ? 'green' : 'yellow'}>
              {user.isAdmin + ''}
            </Tag>
          </span>
        )} />
      </Table>
      <Modal
        className={styles.AccessModal}
        title="Edit Client Users"
        visible={showAccessModal}
        confirmLoading={updating}
        onOk={() => {
          updateClientUsers({
            variables: {
              id: client.id,
              userIds,
            },
          }).then(() => setShowAccessModal(false));
        }}
        onCancel={() => setShowAccessModal(false)}
      >
        <Select
          className={styles.select}
          mode="multiple"
          placeholder="Please select users who have access to this client."
          value={userIds}
          onChange={(ids) => setUserIds(ids)}
          filterOption={(input, option) => toLower(option.children).includes(toLower(input))}
        >
          {map(users, (user) => (
            <Option key={user.id} value={user.id}>
              {`${user.name}<${user.email}>`}
            </Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
});

export default ClientUsers;
